import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageKey } from '../models/storage.model';
import { StorageService } from './storage.service';

const { CURRENT_USER_INFO, AUTH_TOKEN, ROLES, NON_LAB_TESTER } = StorageKey;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl: string;
  links: any;
  authenticationState = new BehaviorSubject(true);
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
  private jsonURL = 'assets/files/CountryCodes.json';
  private userLoggedIn = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private httpNoAuth: HttpClient,
    private router: Router,
    private storage: StorageService,
    private httpBackend: HttpBackend
  ) {
    this.httpNoAuth = new HttpClient(this.httpBackend);
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  // getUserLoggedIn (): Observable<boolean> {
  //   return this.userLoggedIn.asObservable ();
  // }

  // public get userValue (): boolean {
  //   return this.authenticationState.value;
  // }

  public isLogged(): boolean {
    try {
      const currentToken = this.storage.read(AUTH_TOKEN);
      const currentUser = this.storage.read(CURRENT_USER_INFO);
      return !!currentToken && !!currentUser;
    } catch (e) {
      return false;
    }
  }

  login(payload): Observable<any> {
    this.authenticationState.next(false);
    return this.httpNoAuth.post<any>(`/api/auth/token/login/`, payload).pipe(
      map((user) => {
        if (user && user.auth_token) {
          this.storage.save(AUTH_TOKEN, user.auth_token);
          this.authenticationState.next(true);
        }
        return user;
      })
    );
  }

  getPersonalNonLabTester(): Observable<any> {
    return this.http.get(`api/application/personal-non-lab-tester/`).pipe(
      map((response) => {
        if (response) {
          if (response[0] !== undefined) {
            this.storage.save(NON_LAB_TESTER, true);
          }
          return response;
        }
      })
    );
  }

  getUser(): Observable<any> {
    return this.http.get(`api/auth/users/me/`).pipe(
      map((userInfo) => {
        if (userInfo) {
          this.storage.save(CURRENT_USER_INFO, userInfo);
          this.setUserLoggedIn(true);
          this.router.navigate(['home'], { replaceUrl: true });
          this.getPersonalNonLabTester()
            .pipe()
            .subscribe(
              (response) => {},
              (error) => {}
            );
        } else {
          this.storage.remove(AUTH_TOKEN);
          this.storage.remove(CURRENT_USER_INFO);
          this.storage.clear();
          localStorage.clear();
          window.sessionStorage.clear();
        }
        return userInfo;
      })
    );
  }

  getReloadedUser(): Observable<any> {
    return this.http.get(`api/auth/users/me/`).pipe(
      map((userInfo) => {
        if (userInfo) {
          this.storage.save(CURRENT_USER_INFO, userInfo);
          this.setUserLoggedIn(true);
          this.router.navigate(['home', 'profile'], { replaceUrl: true });
        } else {
          this.storage.remove(AUTH_TOKEN);
          this.storage.remove(CURRENT_USER_INFO);
          this.storage.clear();
          localStorage.clear();
        }
        return userInfo;
      })
    );
  }

  saveNewUser(payload) {
    return this.httpNoAuth
      .post<any>(`api/auth/users/`, payload, { headers: this.headers })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  logout() {
    return this.http.post<any>(`api/auth/token/logout/`, {}).pipe(
      map((response) => {
        this.storage.remove(AUTH_TOKEN);
        this.storage.remove(CURRENT_USER_INFO);
        this.storage.remove(ROLES);
        this.storage.remove(NON_LAB_TESTER);
        this.storage.clear();
        localStorage.clear();
        window.sessionStorage.clear();
        this.authenticationState.next(false);
        this.router.navigate(['hprs'], { replaceUrl: true });
        return response;
      })
    );
  }

  resendActivationEmail(payload): Observable<any> {
    return this.httpNoAuth
      .post<any>(`api/auth/users/resend_activation/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  checkEmailAvailability(eQuery) {
    return this.httpNoAuth
      .get<any>(`api/user_management/search-email/?search=${eQuery}`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchUser(eQuery) {
    return this.httpNoAuth
      .get<any>(`api/user_management/search-user/?search_user=${eQuery}`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchUserPost(payload) {
    return this.httpNoAuth
      .post<any>(`api/user_management/search-user/`, payload,{
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchUserWithAuth(eQuery) {
    return this.http
      .get<any>(`api/user_management/search-user-with-auth/?search_user=${eQuery}`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  userPasswordReset(payload) {
    return this.httpNoAuth
      .post<any>(`api/auth/users/reset_password/`, payload, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  userPasswordChange(payload) {
    return this.http.post<any>(`api/auth/users/set_password/`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  activateAccount(uidToken) {
    return this.httpNoAuth
      .post<any>(`api/auth/users/activation/`, uidToken)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  userPasswordResetConfirm(payload) {
    return this.httpNoAuth
      .post<any>(`api/auth/users/reset_password_confirm/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // roleChecker (roles) {
  //   let allowed = false;
  //   let user = this.storage.read (CURRENT_USER_INFO);
  //   let userGroups: String[] = user.groups;
  //   roles.forEach (e => {
  //     if (userGroups.includes (e)) {
  //       allowed = true;
  //       return false;
  //     }
  //   });
  //   return allowed;
  // }

  // roleChecker2 (title: String): boolean {
  //   let show = false;
  //   this.getUserLinkPerms ()
  //     .subscribe (
  //       response => {
  //         this.links = response.menu;
  //
  //         this.links.map (row => {
  //           if (row.displayed_code === title) {
  //             show = true;
  //             // return show
  //           }
  //         });
  //         return show;
  //
  //       }, error => {
  //         Swal.fire (
  //           {
  //             title: 'Warning!',
  //             text: 'No Role Assigned, Please Contact System Administrator for further Actions!',
  //             icon: 'warning',
  //             allowOutsideClick: false,
  //           },
  //         );
  //         show = false;
  //         // return show
  //       },
  //     );
  //   return show;
  //
  // }

  getUserLinkPerms() {
    return this.http.get<any>(`api/system-administration/user-menu/`).pipe(
      map((response) => {
        const roleInfo = response[0].menu_role;
        roleInfo['rolesId'] = response[0].id;
        roleInfo['rolesUser'] = response[0].user;
        this.storage.save(ROLES, response[0].menu_role);
        return response[0].menu_role;
      })
    );
  }

  getUserLinkHandoverPerms() {
    return this.http.get<any>(`api/system-administration/user-hand-over/`).pipe(
      map((response) => {
        return response[0];
      })
    );
  }

  getRoles() {
    return this.httpNoAuth
      .get<any>(`api/system-administration/get-menu-role/`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMenus() {
    return this.http.get<any>(`api/system-administration/admin-menus/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveMenu(payload) {
    return this.http
      .post<any>(`api/system-administration/admin-menus/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  patchMenu(id, payload) {
    return this.http
      .patch<any>(`api/system-administration/admin-menus/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveNewUserRole(payload) {
    return this.httpNoAuth
      .post<any>(`api/system-administration/post-user-menu/`, payload, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAdminRoles() {
    return this.http
      .get<any>(`api/system-administration/admin-menu-roles/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPractitionerDetails(payload) {
    return this.http
      .post<any>(`api/user_management/practitioners-details/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveNewRole(payload) {
    return this.http
      .post<any>(`api/system-administration/admin-menu-roles/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAllUsers() {
    return this.http.get<any>(`api/system-administration/admin-users/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getUserById(id) {
    return this.http
      .get<any>(`api/system-administration/admin-users/${id}/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPractitionerById(id) {
    return this.http
      .get<any>(`api/system-administration/practitioner-users/${id}/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateUserById(id, payload) {
    return this.http
      .patch<any>(`api/system-administration/admin-users/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public loadCountryCodes(): Observable<any> {
    return this.httpNoAuth.get(this.jsonURL).pipe(
      map((response) => {
        return response;
      })
    );
  }

  resolveCountryToObject(code: string, countries: any[]): any {
    let countryObject = '';
    countries.map((row) => {
      if (row.code === code) {
        countryObject = row;
      }
    });
    return countryObject;
  }

  resolveCountryName(code, countries: any[]): any {
    let countryName = '';
    countries.map((row) => {
      if (row.code === code) {
        countryName = row.name;
      }
    });
    return countryName;
  }
}

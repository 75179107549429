import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FooterComponent } from '../../views/dashboard/footer/footer.component';
import { HeaderComponent } from '../../views/dashboard/header/header.component';
import { SidebarComponent } from '../../views/dashboard/sidebar/sidebar.component';
import { SharedMaterialModule } from '../shared-material.module';
import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

const components = [
  AdminLayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  ButtonLoadingComponent,
  SidebarComponent,
  FooterComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    SharedMaterialModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    RouterModule,
  ],
  exports: components,
})
export class SharedComponentsModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeclarationService {
  constructor(private http: HttpClient) {}

  getUserDeclaration(): Observable<any> {
    return this.http.get(`api/user_management/user-declaration/`).pipe(
      map((declaration) => {
        if (declaration) {
          return declaration;
        }
      })
    );
  }

  saveUserDeclaration(payload): Observable<any> {
    return this.http
      .post<any>(`api/user_management/user-declaration/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationUrl = 'api/notification/user-notification/';
  notificationReadUrl = 'api/notification/user-read-notification/';

  constructor(private toaster: ToastrService, private http: HttpClient) {}

  success(title, message, timeout?) {
    this.toaster.success(message, title, {
      timeOut: timeout ? timeout : 8000,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    });
  }

  error(title, message, timeout?) {
    this.toaster.error(message, title, {
      timeOut: timeout ? timeout : 8000,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    });
  }

  warning(title, message, timeout?) {
    this.toaster.warning(message, title, {
      timeOut: timeout ? timeout : 8000,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    });
  }

  info(title, message, timeout?) {
    this.toaster.info(message, title, {
      timeOut: timeout ? timeout : 8000,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    });
  }

  show(title, message, timeout?) {
    this.toaster.show(message, title, {
      timeOut: timeout ? timeout : 8000,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    });
  }

  clear(toastId?: number) {
    this.toaster.clear(toastId);
  }

  // NOTIFICATION COMPONENT

  loadNotifications(user): Observable<any> {
    return this.http.get(this.notificationUrl + '?user=' + user).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateNotification(payload, id) {
    return this.http
      .patch<any>(this.notificationReadUrl + id + '/', payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}

import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKey } from '../../../shared/models/storage.model';
import { AuthService } from '../../../shared/services/auth.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { StorageService } from '../../../shared/services/storage.service';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: any;
  userInfo: any;
  showNotification = true;
  doNotShow = false;
  notification: any;
  userRole: any;
  userRoleInfo: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  notificationDialog: MatDialogRef<NotificationComponent>;

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private storage: StorageService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.user = this.storage.read(StorageKey.CURRENT_USER_INFO);
    if (this.user) {
      this.userInfo = this.user;
    }
    this.userRole = this.storage.read(StorageKey.ROLES);
    if (this.userRole) {
      this.userRoleInfo = this.userRole?.role;
    }
    if (this.userRoleInfo === 'PRACTITIONER') {
      this.doNotShow = false;
    } else if (this.userRoleInfo === 'NON LAB TESTER') {
      this.doNotShow = false;
    } else {
      this.doNotShow = true;
    }
  }

  ngOnInit(): void {
    this.loadUserNotifications();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  logoutInit() {
    this.spinner.show();
    this.authService
      .logout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
  }

  onClose($event, id) {
    $event.stopPropagation();
    this.showNotification = !this.showNotification;
  }

  loadUserNotifications() {
    this.user = this.storage.read(StorageKey.CURRENT_USER_INFO);
    this.notificationService
      .loadNotifications(this.user.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.notification = response;
      });
  }

  onOPenNotification() {
    this.notificationDialog = this.dialog.open(NotificationComponent, {
      disableClose: true,
      autoFocus: true,
      width: '75%',
      height: '100%',
      panelClass: 'custom-dialog-notification',
    });

    this.notificationDialog.componentInstance.onUpdateNotification.subscribe(
      (response: any) => {
        if (response === 'updateNotification') {
          this.loadUserNotifications();
        }
      }
    );
  }
}

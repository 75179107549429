<mat-toolbar class="hprs-header">
  <mat-toolbar-row>
    <button (click)="toggleSideBar()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <span class="small-text-title"> HPRS </span>
    <span class="large-text-title mt-1">
      Health Practitioners Registration System
    </span>
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
        <li>
          <button (click)="onOPenNotification()" mat-icon-button>
            <mat-icon>notifications</mat-icon>
            <span class="notification-number mat-bg-warn">{{
              notification?.length
            }}</span>
          </button>
        </li>
        <li>
          <button
            [matMenuTriggerFor]="accountMenu"
            class="topbar-button-right mr-16 img-button"
            mat-icon-button
          >
            <img
              [src]="
                userInfo?.profile_picture
                  ? userInfo?.profile_picture
                  : 'assets/images/placeholder.jpg'
              "
              alt=""
              mat-card-avatar
            />
          </button>
          <mat-menu #accountMenu="matMenu">
            <div class="current-user text-muted">
              Signed in as <b>{{ user?.first_name }} {{ user?.last_name }}</b>
            </div>
            <mat-divider></mat-divider>
            <button [routerLink]="['profile']" mat-menu-item>
              <mat-icon>account_circle</mat-icon>
              <span>Profile</span>
            </button>
            <button *ngIf="doNotShow" [routerLink]="['handover']" mat-menu-item>
              <mat-icon>waving_hand</mat-icon>
              <span>Handover</span>
            </button>
            <button (click)="logoutInit()" mat-menu-item>
              <mat-icon>logout</mat-icon>
              <span>Log out</span>
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  sideBarOpen = false;
  links = [];

  screenWidth: number;
  menuState: any;

  constructor(private authService: AuthService) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.loadLinks();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  sideBarToggle() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  loadLinks() {
    this.authService
      .getUserLinkPerms()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          let myLinks = [];
          if (response) {
            myLinks = response?.menu;
          } else {
            myLinks = [];
          }
          this.loadHandoverLinks(myLinks);
        },
        (error) => {
          Swal.fire({
            title: 'Warning!',
            text: 'No Role Assigned, Please Contact System Administrator for further Actions!',
            icon: 'warning',
            allowOutsideClick: false,
          });
        }
      );
  }

  combine(array1, array2) {
    return [...array1, ...array2];
  }

  loadHandoverLinks(myLinks) {
    this.authService
      .getUserLinkHandoverPerms()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          let handoverResponse = [];
          if (response) {
            handoverResponse = response?.handover_menu_role?.menu_role?.menu;
          } else {
            handoverResponse = [];
          }
          const newLinks = this.combine(myLinks, handoverResponse);
          this.links = Array.from(
            new Set(newLinks.map((a) => a.displayed_code))
          ).map((id) => {
            return newLinks.find((a) => a.displayed_code === id);
          });
          this.sideBarOpen = true;
        },
        (error) => {
          Swal.fire({
            title: 'Warning!',
            text: 'No Role Handed Over, Please Contact System Administrator for further Actions!',
            icon: 'warning',
            allowOutsideClick: false,
          });
        }
      );
  }
}

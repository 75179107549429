<div class="text-center heading-notification">
  <h4>Notifications ({{ notifications?.length }})</h4>
</div>

<div class="row">
  <div class="col notification-new">
    <p *ngIf="notifications?.length == 0"><i>Sorry, no notification..</i></p>
    <ul *ngFor="let n of notifications">
      <li
        class="alert panel-body"
        [class.alert-success]="n.notification_type == 'SUCCESS'"
        [class.alert-danger]="n.notification_type == 'DANGER'"
        [class.alert-info]="n.notification_type == 'INFO'"
        [class.alert-warning]="n.notification_type == 'WARNING'"
      >
        <a (click)="readNotification(n.id)"> {{ n.message }}</a> <br /><small
          class="text-muted mt-1"
          >{{ n.created_at | date }}</small
        >
      </li>
    </ul>
  </div>
</div>

<div class="row close-notification">
  <div class="col">
    <button mat-button matSuffix mat-icon-button (click)="onClose()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<div class="row" id="sidebar-hprs">
  <div class="col">
    <div class="row sidebar-logo justify-content-center">
      <ul>
        <li>
          <img
            alt=""
            loading="lazy"
            src="assets/images/Coat_of_arms_of_Tanzania.svg-min.png"
            width="100px"
          />
        </li>
      </ul>
    </div>
    <mat-nav-list>

      <mat-list-item *ngIf="showLink('home')" [routerLinkActive]="['active']" [routerLink]="['home']">
        <mat-icon class="">alarm</mat-icon>
        <span style="margin-left: 1.9em;">Home</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('dashboard')" [routerLinkActive]="['active']" [routerLink]="['dashboard']">
        <mat-icon class="">dashboard</mat-icon>
        <span style="margin-left: 1.9em;">Dashboard</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('personal_information')" [routerLinkActive]="['active']"
                     [routerLink]="['personal-information']">
        <mat-icon class="">person_pin_circle</mat-icon>
        <span style="margin-left: 1.9em;">Personal Information</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('academic')" [routerLinkActive]="['active']" [routerLink]="['academic']">
        <mat-icon class="">school</mat-icon>
        <span style="margin-left: 1.9em;">Academic Qualification</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('attachment_panel')" [routerLinkActive]="['active']"
                     [routerLink]="['attachment-panel']">
        <mat-icon class="">attach_file</mat-icon>
        <span style="margin-left: 1.9em;">Attachment Panel</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('work_experience')" [routerLinkActive]="['active']"
                     [routerLink]="['work-experience']">
        <mat-icon class="">work_outline</mat-icon>
        <span style="margin-left: 1.9em;">Work Experience</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('referee_information')" [routerLinkActive]="['active']"
                     [routerLink]="['referee-information']">
        <mat-icon class="">how_to_reg</mat-icon>
        <span style="margin-left: 1.9em;">Referee Information</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('declaration')" [routerLinkActive]="['active']" [routerLink]="['declaration']">
        <mat-icon class="">touch_app</mat-icon>
        <span style="margin-left: 1.9em;">Declaration</span>
      </mat-list-item>

    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-divider></mat-divider>
    <mat-divider></mat-divider>
    <mat-nav-list>

      <mat-list-item *ngIf="showLink('registration')" [routerLinkActive]="['active']" [routerLink]="['registration']">
        <mat-icon class="">person_add</mat-icon>
        <span style="margin-left: 1.9em;">Registration</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('licencing')" [routerLinkActive]="['active']"
                     [routerLink]="['licensing', 'license']">
        <mat-icon class="">card_membership</mat-icon>
        <span style="margin-left: 1.9em;">Licencing</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('non-lab-tester')" [routerLinkActive]="['active']"
                     [routerLink]="['non-lab-tester']">
        <mat-icon class="">hourglass_full</mat-icon>
        <span style="margin-left: 1.9em;">Non-Lab Tester</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('debt-management')" [routerLinkActive]="['active']"
                     [routerLink]="['debt-management']">
        <mat-icon class="">how_to_vote</mat-icon>
        <span style="margin-left: 1.9em;">Debt Management</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('payment')" [routerLinkActive]="['active']" [routerLink]="['billing', 'payment']">
        <mat-icon class="">attach_money</mat-icon>
        <span style="margin-left: 1.9em;">Payment</span>
      </mat-list-item>

    </mat-nav-list>
    <mat-accordion>

      <mat-expansion-panel *ngIf="showLink('registrar')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>library_add</mat-icon>
          </mat-panel-title>
          <mat-panel-description style="margin-left: -28px;">
            <strong>Registrar</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item>
            <button *ngIf="showLink('registrar')" [routerLinkActive]="['active']" [routerLink]="['registrar']"
                    mat-button fxFlexFill class="material-button text-left">Registration Panel
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('licence')" [routerLinkActive]="['active']"
                    [routerLink]="['licensing', 'registrar']" mat-button fxFlexFill class="material-button text-left">
              Licence Panel
            </button>
          </mat-list-item>
          <!--          <mat-list-item> <button *ngIf="showLink('non-lab-tester-panel')" [routerLinkActive]="['active']" [routerLink]="['non-lab-tester']" mat-button fxFlexFill class="material-button">Non-Lab Tester Panel</button> </mat-list-item>-->
          <mat-list-item>
            <button *ngIf="showLink('billing')" [routerLinkActive]="['active']" [routerLink]="['billing', 'billing']"
                    mat-button fxFlexFill class="material-button text-left">Accountant Panel
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('practitioner-details')" [routerLinkActive]="['active']"
                    [routerLink]="['practitioner-details']" mat-button fxFlexFill class="material-button text-left">
              Practitioner Details
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('registrar')" [routerLinkActive]="['active']" [routerLink]="['debts-management']"
                    mat-button fxFlexFill class="material-button text-left">Debts Management
            </button>
          </mat-list-item>
        </mat-list>

      </mat-expansion-panel>

    </mat-accordion>
    <mat-accordion>

      <mat-expansion-panel *ngIf="showLink('internship')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>speaker_group</mat-icon>
          </mat-panel-title>
          <mat-panel-description style="margin-left: -28px;">
            <strong>Internship</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item>
            <button *ngIf="showLink('internship-configuration')" [routerLinkActive]="['active']"
                    [routerLink]="['internship-configuration']" mat-button fxFlexFill class="material-button text-left">
              Configuration
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('internship-application')" [routerLinkActive]="['active']"
                    [routerLink]="['internship-application']" mat-button fxFlexFill class="material-button text-left">
              Application
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('internship-register')" [routerLinkActive]="['active']"
                    [routerLink]="['internship-register']" mat-button fxFlexFill class="material-button text-left">
              Register Panel
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('internship-register')" [routerLinkActive]="['active']"
                    [routerLink]="['internship-reports']" mat-button fxFlexFill class="material-button text-left">
              Internship Reports
            </button>
          </mat-list-item>
        </mat-list>

      </mat-expansion-panel>

    </mat-accordion>
    <mat-accordion>

      <mat-expansion-panel *ngIf="showLink('reports')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>library_books</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            <strong style="margin-left: -28px;">Reports</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item>
            <button *ngIf="showLink('reports')" [routerLinkActive]="['active']"
                    [routerLink]="['reports/general-registration-report']" mat-button fxFlexFill
                    class="material-button text-left">General Registration
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('reports')" [routerLinkActive]="['active']"
                    [routerLink]="['reports/general-registration-report-aggregate']" mat-button fxFlexFill
                    class="material-button text-left">General Registration Aggregate
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('reports')" [routerLinkActive]="['active']"
                    [routerLink]="['reports/registration-by-service']" mat-button fxFlexFill
                    class="material-button text-left">General Registration By Service
            </button>
          </mat-list-item>
          <mat-list-item>
            <button *ngIf="showLink('reports')" [routerLinkActive]="['active']"
                    [routerLink]="['reports/licence-report']" mat-button fxFlexFill class="material-button text-left">
              General Licence Report
            </button>
          </mat-list-item>
        </mat-list>

      </mat-expansion-panel>

    </mat-accordion>
    <mat-accordion>

      <mat-expansion-panel *ngIf="showLink('examination')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>how_to_vote</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            <strong style="margin-left: -28px;">Examination</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item>
            <button *ngIf="showLink('examination-settings')" [routerLinkActive]="['active']"
                    [routerLink]="['examination']" mat-button fxFlexFill class="material-button">Examination Panel
            </button>
          </mat-list-item>
        </mat-list>

        <mat-list>
          <mat-list-item>
            <button *ngIf="showLink('examination-application')" [routerLinkActive]="['active']"
                    [routerLink]="['examination-application']" mat-button fxFlexFill class="material-button">Examination
              Application
            </button>
          </mat-list-item>
        </mat-list>

      </mat-expansion-panel>

    </mat-accordion>
    <mat-nav-list>

      <mat-list-item *ngIf="showLink('cpd')" [routerLinkActive]="['active']" [routerLink]="['cpd']">
        <mat-icon class="">card_membership</mat-icon>
        <span style="margin-left: 1.9em;">CPD</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('documentation')" [routerLinkActive]="['active']" [routerLink]="['documentation']">
        <mat-icon class="">insert_drive_file</mat-icon>
        <span style="margin-left: 1.9em;">Documentation</span>
      </mat-list-item>

      <mat-list-item *ngIf="showLink('system_administration')" [routerLinkActive]="['active']"
                     [routerLink]="['system-administration']">
        <mat-icon class="">settings</mat-icon>
        <span style="margin-left: 1.9em;">System Administration</span>
      </mat-list-item>


    </mat-nav-list>
  </div>
</div>


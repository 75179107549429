import { Injectable } from '@angular/core';
import { StorageKey } from '../models/storage.model';
import ls from 'localstorage-slim';


@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  public save(key: StorageKey, value: any) {
    ls.set(key, value, { encrypt: true })
  }

  public read(key: StorageKey): any {
    return ls.get(key, {decrypt: true});
  }

  public remove(key: StorageKey) {
    return this.storage.removeItem(key);
  }

  public clear() {
    this.storage.clear();
  }
}

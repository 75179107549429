<app-header (toggleSideBarForMe)="sideBarToggle()"></app-header>

<mat-drawer-container>
  <mat-drawer
    [mode]="screenWidth >= 767 ? 'side' : 'over'"
    [opened]="screenWidth >= 767 ? sideBarOpen : !sideBarOpen"
    style="width: auto; background: #ffffff !important"
  >
    <app-sidebar [links]="links" class="main-header"></app-sidebar>
  </mat-drawer>

  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

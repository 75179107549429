import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { StorageKey } from '../../../shared/models/storage.model';
import { AuthService } from '../../../shared/services/auth.service';
import { DeclarationService } from '../../../shared/services/declaration.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { StorageService } from '../../../shared/services/storage.service';

const { CURRENT_USER_INFO, ROLES } = StorageKey;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input('links') links: any;

  destroy$: Subject<boolean> = new Subject<boolean>();
  declared = false;
  hideNonLab = true;
  showNonLabPanel = false;
  user: any;
  roles: any;
  councilCode: any;

  constructor(
    private authService: AuthService,
    private notify: NotificationService,
    private storageService: StorageService,
    private declarationService: DeclarationService
  ) {
    this.user = this.storageService.read(CURRENT_USER_INFO);
    this.roles = this.storageService.read(ROLES);
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  loadRole() {
    this.authService
      .getUserLinkPerms()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (response.role === 'PRACTITIONER') {
            this.getUserDeclaration();
          } else {
            this.declared = true;
            this.hideNonLab = true;
            this.showNonLabPanel =
              (this.roles?.role === 'REGISTRAR' ||
                this.roles?.role === 'ACCOUNTANT' ||
                this.roles?.role === 'ADMIN') &&
              this.user.user_profession[0].profession.profession_council
                .council_short_name === 'HLPC';
          }
        },
        (error) => {
          Swal.fire({
            title: 'Warning!',
            text: 'No Role Assigned, Please Contact System Administrator for further Actions!',
            icon: 'warning',
            allowOutsideClick: false,
          });
        }
      );
  }

  getUserDeclaration() {
    this.declarationService
      .getUserDeclaration()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (
            response.length > 0 &&
            (this.user?.council.length > 0 ||
              this.user?.user_profession.length > 0)
          ) {
            this.councilCode =
              this.user?.user_profession[0]?.profession?.profession_council?.council_short_name;
            this.hideNonLab = false;
            this.declared = true;
          } else {
            if (response[0]?.user?.personal_non_lab_tester?.non_lab_tester) {
              this.hideNonLab = true;
            } else {
              this.notify.warning(
                'Warning!',
                `No Declaration of Information's Submitted`
              );
              this.hideNonLab = false;
              this.declared = false;
            }
          }
        },
        (error) => {
          this.notify.error(
            'Error!',
            `Error on Declaration of Information's Submitted`
          );
        }
      );
  }

  showLink(title: string): boolean {
    let show = false;
    this.links.forEach((row) => {
      if (row.displayed_code === title) {
        show = true;
        return show;
      }
    });
    return show;
  }
}

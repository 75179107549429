import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKey } from '../../../shared/models/storage.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { StorageService } from '../../../shared/services/storage.service';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  user: any;
  notifications: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  onUpdateNotification = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<HeaderComponent>,
    private notificationService: NotificationService,
    private storage: StorageService
  ) {
    this.user = this.storage.read(StorageKey.CURRENT_USER_INFO);
  }

  ngOnInit(): void {
    this.changePosition();
    this.loadUserNotifications();
  }

  onClose() {
    this.dialogRef.close();
    this.onUpdateNotification.emit('updateNotification');
  }

  changePosition() {
    this.dialogRef.updatePosition({ top: '50px', right: '1px' });
  }

  loadUserNotifications() {
    this.user = this.storage.read(StorageKey.CURRENT_USER_INFO);
    this.notificationService
      .loadNotifications(this.user.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.notifications = response;
      });
  }

  readNotification(id) {
    const payload = {
      read: true,
      expires: true,
    };

    this.notificationService
      .updateNotification(payload, id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.loadUserNotifications();
        }
      });
  }
}

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StorageKey } from './shared/models/storage.model';
import { AuthService } from './shared/services/auth.service';
import { NotificationService } from './shared/services/notification.service';
import { RoutePartsService } from './shared/services/route-parts.service';
import { StorageService } from './shared/services/storage.service';
import { UiLibIconService } from './shared/services/ui-lib-icon.service';

const { CURRENT_USER_INFO } = StorageKey;

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  appTitle = 'HPRS';
  pageTitle = '';

  loadingText = 'Loading, Please wait...';

  user: any;
  userInfo: any;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(
    public title: Title,
    private router: Router,
    private iconService: UiLibIconService,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive,
    private spinner: NgxSpinnerService,
    private notify: NotificationService,
    private storageService: StorageService,
    private routePartsService: RoutePartsService
  ) {
    this.user = this.storageService.read(CURRENT_USER_INFO);

    if (this.user) {
      this.userInfo = this.user;
    }

    // if (!authService.isLogged ()) {
    //   this.navigateTo ('/hprs');
    // }

    // sets an idle timeout of 30 minutes.
    idle.setIdle(1800);
    // sets a timeout period of 5 minutes. after 30 minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(300);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = `You've gone idle!`;
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    if (authService.isLogged()) {
      idle.watch();
      this.timedOut = false;
    } else {
      idle.stop();
    }

    authService.authenticationState.pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        if (!response) {
          if (!this.authService.isLogged()) {
            this.authService
              .logout()
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (logout) => {},
                (error) => {}
              );
          }
        }
      },
      (error) => {
        this.notify.error('Error!', `State Error: ${error}`);
      }
    );
    this.iconService.init();

    // init loader when lazy loading the next module
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.spinner.show();
      } else if (event instanceof NavigationEnd) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);

    this.changePageTitle();
    // this.setUpAnalytics();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.authService.logout().subscribe((response) => {
    });
  }

  public navigateTo(path: string, url?: string) {
    url = url || path;
    this.router.navigate([url], { replaceUrl: true });
  }

  ngAfterViewInit() {}

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroy$))
      .subscribe((routeChange) => {
        const routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) {
          return this.title.setTitle(this.appTitle);
        }
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }

  setUpAnalytics() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'G-9H3H12F5M1', {
          page_path: event.urlAfterRedirects,
        });
      });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from './components/components.module';
import { AuthGuard } from './guards/auth.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedComponentsModule, NgbModule],
  providers: [AuthGuard],
  exports: [NgbModule, SharedComponentsModule],
})
export class SharedModule {}
